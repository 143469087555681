import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SingleChoinceTimezone } from '@repo/shared/components/forms';
import { EditIcon } from '@repo/shared/components/icons';
import { SnackbarAnchorOrigin as anchorOrigin } from '@repo/shared/libs/snackbar';
import { joinErrors } from '@repo/shared/libs/utils';
import graphql from 'babel-plugin-relay/macro';
import { makeRequired, makeValidate, TextField } from 'mui-rff';
import { useSnackbar } from 'notistack';
import { memo, useState } from 'react';
import { Form } from 'react-final-form';
import { useFragment, useMutation } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { object, string } from 'yup';
import type { locationAboutTab_query$key } from './__generated__/locationAboutTab_query.graphql';
import type { locationAboutTab_updateLocationMutation } from './__generated__/locationAboutTab_updateLocationMutation.graphql';

type Props = {
  rootDataRelay: locationAboutTab_query$key;
  organizationId: string | null;
};

interface LocationDetails {
  name: string;
  about: string | null;
  timezone: string;
}

const locationSchema = object({
  name: string().min(3, 'Location name must be at least three charcters long.').required('Location name is required'),
  about: string().nullable(),
  timezone: string().required('Timezone is required'),
});

const LocationAboutTab = ({ rootDataRelay, organizationId }: Props) => {
  const rootData = useFragment<locationAboutTab_query$key>(
    graphql`
      fragment locationAboutTab_query on Query {
        location(id: $locationId) {
          id
          name
          about
          timezone
          organization {
            name
          }
          canModify
        }
      }
    `,
    rootDataRelay,
  );

  const [commitUpdateLocation] = useMutation<locationAboutTab_updateLocationMutation>(graphql`
    mutation locationAboutTab_updateLocationMutation($input: UpdateLocationInput!) @raw_response_type {
      updateLocation(input: $input) {
        location {
          id
          name
          about
          timezone
        }
      }
    }
  `);

  const { enqueueSnackbar } = useSnackbar();
  const [editing, setEditing] = useState(false);
  const validate = makeValidate(locationSchema);
  const requiredFields = makeRequired(locationSchema);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleLocationUpdateClick = ({ name, about, timezone }: LocationDetails) => {
    if (!rootData.location) {
      return;
    }

    commitUpdateLocation({
      variables: {
        input: {
          clientMutationId: uuidv4(),
          id: rootData.location.id,
          name,
          about,
          timezone,
          organizationId,
        },
      },
      onCompleted: (_, errors) => {
        if (errors && errors.length > 0) {
          enqueueSnackbar(`Failed to update location '${name}'. Error: ${joinErrors(errors)}`, {
            variant: 'error',
            anchorOrigin,
          });
        } else {
          setEditing(false);
        }
      },
      onError: (error) => {
        enqueueSnackbar(`Failed to update location '${name}'. Error: ${error.message}`, {
          variant: 'error',
          anchorOrigin,
        });
      },
      optimisticResponse: {
        updateLocation: {
          location: {
            id: rootData.location.id,
            name,
            about,
            timezone,
          },
        },
      },
    });
  };

  const handleCancelClick = () => {
    setEditing(false);
  };

  if (!rootData.location) {
    return null;
  }

  const location = rootData.location;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!editing && rootData.location.canModify && (
          <Button size="large" color="primary" onClick={handleEditClick}>
            <EditIcon />
          </Button>
        )}
      </Box>
      {!editing && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'left',
            marginBottom: 1,
          }}
        >
          <Grid>
            <Stack direction={'row'}>
              <Typography gutterBottom variant="h6">
                About
              </Typography>
              <Typography gutterBottom variant="body1" sx={{ whiteSpace: 'pre-line', marginLeft: 1 }}>
                {location.about}
              </Typography>
            </Stack>

            <Stack direction={'row'}>
              <Typography gutterBottom variant="h6">
                Timezone
              </Typography>
              <Typography gutterBottom variant="body1" sx={{ whiteSpace: 'pre-line', marginLeft: 1 }}>
                {location.timezone}
              </Typography>
            </Stack>

            {location.organization && (
              <Stack direction={'row'}>
                <Typography gutterBottom variant="h6">
                  Organization
                </Typography>

                <Typography gutterBottom variant="body1" sx={{ whiteSpace: 'pre-line', marginLeft: 1 }}>
                  {location.organization.name}
                </Typography>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
      {editing && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 1,
          }}
        >
          <Paper elevation={24} sx={{ padding: 3 }}>
            <Form
              onSubmit={handleLocationUpdateClick}
              initialValues={{
                name: location.name,
                about: location.about,
                timezone: location.timezone,
              }}
              validate={validate}
              render={({ handleSubmit }) => (
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1 },
                  }}
                  autoComplete="off"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <TextField label="Name" name="name" required={requiredFields.name} />
                  <TextField label="About" name="about" required={requiredFields.about} multiline={true} />
                  <SingleChoinceTimezone name="timezone" required={requiredFields.timezone} />

                  <Stack sx={{ flex: 1, justifyContent: 'flex-end' }} direction="row" spacing={2}>
                    <Button color="secondary" variant="contained" onClick={handleCancelClick}>
                      Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                      Update
                    </Button>
                  </Stack>
                </Box>
              )}
            />
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default memo(LocationAboutTab);
