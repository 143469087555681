/**
 * @generated SignedSource<<abe27f7b74305f387198c31f4f71082d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizationCard_Query$data = {
  readonly me: {
    readonly defaultOrganization: {
      readonly uniqueId: string;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "organizationCard_Query";
};
export type organizationCard_Query$key = {
  readonly " $data"?: organizationCard_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"organizationCard_Query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "organizationCard_Query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerDetails",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerOrganizationDetails",
          "kind": "LinkedField",
          "name": "defaultOrganization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uniqueId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f83682a940065527673eb9361cb4105e";

export default node;
