"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleCalendarSlotProps = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
exports.SimpleCalendarSlotProps = {
    leftArrowIcon: { fontSize: 'medium' },
    rightArrowIcon: { fontSize: 'medium' },
    previousIconButton: {
        size: 'medium',
    },
    nextIconButton: {
        size: 'medium',
    },
    actionBar: {
        actions: [],
    },
};
const EmptyCalendarToolbar = () => {
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
};
exports.default = (0, react_1.memo)(EmptyCalendarToolbar);
