"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNetwork = createNetwork;
exports.getEnvironment = getEnvironment;
const relay_runtime_1 = require("relay-runtime");
const uuid_1 = require("uuid");
function createNetwork(endpoint, token) {
    return relay_runtime_1.Network.create(async (params, variables) => {
        const headers = {
            'Content-Type': 'application/json',
            'X-Correlation-Id': (0, uuid_1.v4)(),
        };
        if (token) {
            headers['Authorization'] = token;
        }
        const response = await fetch(endpoint, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                query: params.text,
                variables,
            }),
        });
        return await response.json();
    });
}
let clientEnvironment;
function getEnvironment(endpoint, token) {
    if (typeof window === 'undefined') {
        return new relay_runtime_1.Environment({
            network: createNetwork(endpoint, token),
            store: new relay_runtime_1.Store(new relay_runtime_1.RecordSource()),
            isServer: true,
        });
    }
    else {
        if (clientEnvironment == null) {
            clientEnvironment = new relay_runtime_1.Environment({
                network: createNetwork(endpoint, token),
                store: new relay_runtime_1.Store(new relay_runtime_1.RecordSource()),
                isServer: false,
            });
        }
        return clientEnvironment;
    }
}
